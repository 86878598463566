import React from "react";
import { Button, Image } from "@fluentui/react-northstar";
import { customLogout } from "../lib/authHelpers";
import { useTranslation } from "react-i18next";
import { useUserData } from "../../utils/context/userContext";
import useScreenSize from "../../utils/hooks/useScreenSize";
import { HeaderProps, LocationMode } from "../../utils/types/common";
import TabletMenu from "./TabletMenu";
import { Feedback } from "../feedback/Feedback";

function Header({ isAdmin, inTeams, handleModeChange, themeString, mode }: HeaderProps) {
  const { t } = useTranslation();
  const { setUserData, isReportAdmin } = useUserData();
  const { isTablet } = useScreenSize();

  return (
    <>
      {!isTablet ? (
        <div className="flex justify-space-between align-center" style={{ padding: "0.5em", height: "4em", marginLeft: "0.5em" }}>
          <Image
            id="cclogo"
            styles={{
              height: "100%",
              filter: themeString === "dark" || themeString === "contrast" ? "invert(100%)" : "",
              WebkitFilter: themeString === "dark" || themeString === "contrast" ? "invert(100%)" : "",
            }}
            src="./img/cccoachlogo.svg"
          />
          <div className="flex gap-large">
            <div className="flex gap-small align-center">
              <Feedback />
              {isAdmin && (
                <>
                  <Button
                    content={t("Header.configureButton")}
                    primary={mode === "configure"}
                    onClick={() => handleModeChange(LocationMode.CONFIGURE)}
                  ></Button>
                  <Button
                    content={t("Header.adminPanelButton")}
                    primary={mode === "admin"}
                    onClick={() => handleModeChange(LocationMode.ADMIN)}
                  ></Button>
                </>
              )}
              {isReportAdmin && (
                <Button
                  content={t("Header.reporting")}
                  primary={mode === "reportadmin"}
                  onClick={() => handleModeChange(LocationMode.REPORTADMIN)}
                ></Button>
              )}
              {!inTeams && (
                <Button content={"CyberCoach"} primary={mode === "cybercoach"} onClick={() => handleModeChange(LocationMode.CYBERCOACH)}></Button>
              )}
              <Button
                content={t("Header.profileButton")}
                primary={mode === "profile"}
                onClick={() => {
                  setUserData(null);
                  handleModeChange(LocationMode.PROFILE);
                }}
              ></Button>
            </div>
            {!inTeams && <Button primary content={t("Header.logoutButton")} onClick={() => customLogout()}></Button>}
          </div>
        </div>
      ) : (
        <TabletMenu isAdmin={isAdmin} inTeams={inTeams} handleModeChange={handleModeChange} themeString={themeString} mode={mode} />
      )}
    </>
  );
}

export default Header;
