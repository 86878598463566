import React, { useState, useRef, useEffect } from "react";
import "../Profile.css";
import {
  Loader,
  Card,
  Header,
  Text,
  Button,
  PopupIcon,
  CloseIcon,
  Dialog,
  FilesTxtIcon,
  Alert,
  ErrorIcon,
  TextArea,
  TrashCanIcon,
  Input,
  ChevronEndMediumIcon,
  Divider,
} from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";
import { fetchFromBackend } from "../../lib/makeBackendCall";
import { AssessmentsProps } from "../../../utils/types/profile";
import { useAlertData } from "../../../utils/context/alertContext";
import { AssessmentsItem, QuestionSet } from "../../../utils/types/assessments";
import useScreenSize from "../../../utils/hooks/useScreenSize";
import ExportToExcel from "../../elements/ExportToExcel";
import { useCompanyConfigurationData } from "../../../utils/context/companyConfigurationContext";

export function Assessments({ assessments, setAssessments }: AssessmentsProps) {
  const { t } = useTranslation();
  const { isMobile } = useScreenSize();
  const { updateError, updateSuccess } = useAlertData();
  const { companyConfiguration } = useCompanyConfigurationData();
  const excelLink = useRef<HTMLButtonElement>(null);
  const [openItem, setOpenItem] = useState<any>();
  const [excelData, setExcelData] = useState<any>([]);
  const [reportFail, setReportFail] = useState<boolean>(false);

  function exportAssessment(assessmentsItem: AssessmentsItem) {
    setReportFail(false);
    try {
      const titleRow = [assessmentsItem?.assessmentName];

      const headerRow = [t("Profile.assessments.question"), t("Profile.assessments.answer")];

      const reportData = assessmentsItem?.assessment?.questionSet.map((set: QuestionSet) => [set.question, set.userAnswer]);
      const excelData = [titleRow, headerRow, ...reportData];

      setExcelData(excelData);
    } catch (error) {
      console.log("Failed to generate report");
      setReportFail(true);
    }
  }
  async function deleteAssessment(assessmentId: number) {
    setAssessments(null);
    const [deleteData, deleteResponseStatus] = await fetchFromBackend("assessment/" + assessmentId, "DELETE");
    if (deleteResponseStatus === 200) {
      updateSuccess(t("Profile.assessments.deleteSuccessMessage"));
      //get a new list of assessments
      const [data, responseStatus] = await fetchFromBackend("assessment");
      if (responseStatus === 200) {
        setAssessments(data);
      } else {
        setAssessments(undefined);
      }
    } else {
      updateError(t("Profile.assessments.deleteErrorMessage"));
    }
  }

  useEffect(() => {
    if (excelData.length && excelLink.current) {
      excelLink.current.click();
      setExcelData([]);
    }
  }, [excelData, excelLink]);

  return (
    <Card
      aria-roledescription="card avatar"
      elevated
      inverted
      className="profileCard"
      id="assessments"
      hidden={!companyConfiguration?.showAssessments}
    >
      <Card.Header>
        <div className="flex gap-medium">
          <div className="flex column">
            <Header content={t("Profile.assessments.title")} className="cardHeadline" />
            <Text content={t("Profile.assessments.subTitle")} size="small" />
          </div>
        </div>
      </Card.Header>
      <div className="flex column align-center justify-space-evenly gap-small full-width" style={{ maxHeight: "80%", overflowY: "scroll" }}>
        {assessments === null ? (
          <Loader />
        ) : assessments ? (
          <>
            {reportFail && <Alert danger icon={<ErrorIcon />} content={t("Profile.assessments.reportErrorMessage")} />}
            <div className="flex column justify-start gap-small full-height">
              {assessments.map((assessmentsItem: AssessmentsItem) => {
                return (
                  <div key={assessmentsItem?.assessmentId} className="flex align-center justify-space-between full-width">
                    <div className="flex column">
                      <Text size="small" weight="bold">
                        {new Date(assessmentsItem?.assessmentdt).toLocaleString()}
                      </Text>
                      <Text>{assessmentsItem.assessmentName}</Text>
                    </div>
                    <div className="flex">
                      <Button
                        secondary
                        icon={<PopupIcon />}
                        content={t("Profile.assessments.result")}
                        size="small"
                        onClick={() => setOpenItem({ ...assessmentsItem })}
                      />
                      <Button
                        secondary
                        icon={<FilesTxtIcon />}
                        content={t("Profile.assessments.export")}
                        size="small"
                        onClick={() => exportAssessment(assessmentsItem)}
                      />
                      <Dialog
                        cancelButton={t("General.Cancel")}
                        confirmButton={t("General.delete")}
                        content={t("Profile.assessments.deleteAssessmentMessage")}
                        header={t("Profile.assessments.deleteAssessmentTitle")}
                        onConfirm={() => deleteAssessment(assessmentsItem.assessmentId)}
                        trigger={
                          <Button styles={{ marginLeft: "0.5rem" }} size="small" icon={<TrashCanIcon />} iconOnly title={t("General.delete")} />
                        }
                      />
                      <ExportToExcel
                        id={assessmentsItem?.assessmentId.toString()}
                        apiData={excelData}
                        innerRef={excelLink}
                        fileName={assessmentsItem.assessmentName + "_" + assessmentsItem.assessmentdt.replace(".", "")}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      <Dialog
        styles={{ maxHeight: isMobile ? "100%" : "80%", width: "60em", overflow: "scroll" }}
        open={!!openItem}
        onConfirm={() => setOpenItem(undefined)}
        onCancel={() => setOpenItem(undefined)}
        content={
          <div className="flex column align-center gap-medium full-width full-height">
            <div className="flex gap-medium">
              <div className="flex gap-smaller">
                <Text weight="bold" content={t("General.date") + ":"} />
                <Text content={new Date(openItem?.assessmentdt).toLocaleString()} />
              </div>
            </div>
            <div className="flex column ful-width gap-large" style={{ height: "95%", marginTop: "1rem" }}>
              {openItem?.assessment?.questionSet &&
                openItem.assessment.questionSet.map((set: QuestionSet, index: number) => {
                  const questionSplit = set.question.split("] ");
                  const setQuestion = questionSplit.length > 1 ? questionSplit[1] : questionSplit[0];
                  return (
                    <div key={set.question}>
                      <div className="flex column gap-smaller">
                        <div className="flex gap-smaller full-width">
                          <Text weight="bold" size="large">
                            {index + 1 + ")"}
                          </Text>
                          <TextArea fluid readOnly value={setQuestion}></TextArea>
                        </div>
                        <div className="flex gap-smaller align-center full-width">
                          <ChevronEndMediumIcon outline />
                          <Input fluid value={set.userAnswer} readOnly />
                        </div>
                      </div>
                      <Divider />
                    </div>
                  );
                })}
            </div>
          </div>
        }
        header={openItem?.assessmentName}
        headerAction={{
          icon: <CloseIcon />,
          title: t("General.Close"),
          onClick: () => setOpenItem(undefined),
        }}
      />
    </Card>
  );
}
