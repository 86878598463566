import React from "react";
import { Link } from "react-router-dom";
import { Button, Text, Input, Alert, ErrorIcon, QuestionCircleIcon } from "@fluentui/react-northstar";
import { customLogout } from "../lib/authHelpers";

export function InCorrectWorkSpace({ customLogout }: { customLogout: () => void }) {
  return (
    <div className="flex column gap-large">
      <Text>
        You have logged in with a workspace that doesn&apos;t have CyberCoach installed. Please verify you logged in with the correct workspace. The
        previous Slack login screen has a workspace selector top right of the screen.
      </Text>
      <Button primary onClick={() => customLogout()}>
        Back to Login
      </Button>
    </div>
  );
}

export function EnterLicenseCode({
  teamName,
  validLicense,
  attempts,
  loading,
  enterLicense,
  licenseInputRef,
}: {
  teamName: string;
  validLicense: boolean | null | undefined;
  attempts: number;
  loading: boolean;
  enterLicense: () => void;
  licenseInputRef: React.Ref<HTMLInputElement>;
}) {
  return (
    <>
      <div className="flex column gap-small">
        <Text>Hi!</Text>
        <Text>
          It appears your organization has not yet taken CyberCoach into use. If you have purchased CyberCoach and have the license code please enter
          it here.
        </Text>
        {/* if Slack we also check the teamName this should be shown to users to avoid confusion if using wrong workspace */}
        {teamName && (
          <div className="flex align-center justify-space-between gap-medium">
            <Text styles={{ width: "80%" }}>
              You are signed in with Slack Workspace <b>{teamName}</b>. If you don&apos;t think you should be seeing this view check with your
              IT-admins that you are using the workspace in which CyberCoach is installed. The correct workspace is the one where you also see
              CyberCoach as a Slack application.
            </Text>
            <Button styles={{ width: "20%" }} secondary onClick={() => customLogout()}>
              Back to Login
            </Button>
          </div>
        )}
      </div>
      <div className="flex column align-start gap-small">
        <div className="flex gap-small full-width">
          <Input
            ref={licenseInputRef}
            fluid
            id="licenseInput"
            styles={{ width: "80%" }}
            placeholder={"Enter license code here"}
            disabled={loading || attempts >= 3 || !!validLicense}
          />
          <Button primary content={"Submit"} onClick={() => enterLicense()} loading={loading} disabled={attempts >= 3} />
        </div>
        {((!validLicense && validLicense !== null) || attempts >= 3) && (
          <Alert
            icon={<ErrorIcon />}
            warning
            content={
              attempts >= 3
                ? "You have exceeded the amount of attempts for entering the license number. Please try again later."
                : "The license number you entered is invalid. You have " + (3 - attempts) + " attempts left."
            }
          />
        )}
      </div>
    </>
  );
}

export function ModalHeader({ modal, teamName }: { modal: string | null; teamName: string }) {
  let headerText = "Organization " + (teamName ?? "") + " not known by CyberCoach";
  if (modal && modal === "Company") {
    headerText = "Configure your organization";
  }

  return (
    // check if vAlign="end" is needed instead hAlign="stretch"
    <div className="flex align-stretch justify-space-between gap-large">
      <Text content={headerText} size="larger" />
      <Link to="https://support.cybercoach.com/en/welcome-to-cybercoach/marketplace-support" target="_blank" rel="noopener noreferrer">
        <Button icon={<QuestionCircleIcon />} content={"Help"} size="small" />
      </Link>
    </div>
  );
}
