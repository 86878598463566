import React from "react";
import "./Progress.css";
import { Text, Divider } from "@fluentui/react-northstar";
import useScreenSize from "../../../../utils/hooks/useScreenSize";
import { TrainingStatsProps } from "../../../../utils/types/progress";
import { useTranslation } from "react-i18next";

function TrainingsStats({ learningPathInfo, customTrainings }: TrainingStatsProps) {
  const { t } = useTranslation();
  const { isMobile } = useScreenSize();
  const customCompleted = customTrainings?.filter((training) => training.completed);
  // include custom trainings to all trainings count
  const allCompletedTrainings = customCompleted?.length
    ? learningPathInfo.completedTrainings + customCompleted?.length
    : learningPathInfo.completedTrainings;
  const allTrainings = customTrainings?.length ? learningPathInfo.trainings + customTrainings?.length : learningPathInfo.trainings;

  return (
    <div className={`flex ${!isMobile && "column"} align-center justify-start gap-medium trainingStatsContainer`}>
      {/* show mandatory training statistics only if learning path has mandatory trainings */}
      {learningPathInfo?.mandatoryTrainings > 0 && (
        <div className="flex justifyl-end align-center trainingStatsElement">
          <div className="flex column align-center trainingStatsElementContainer">
            <Text size="larger" weight="bold" content={learningPathInfo.mandatoryCompleted} />
            <Divider color="black" important className="trainingStatsElementDivider" />
            <Text size="larger" weight="bold" content={learningPathInfo.mandatoryTrainings} />
          </div>
          <Text align="center" size="small" content={t("Profile.LearningPath.mandatoryCompleted")} styles={{ width: "65%" }} />
        </div>
      )}
      {customTrainings?.length ? (
        <div className="flex align-center justify-end trainingStatsElement">
          <div className="flex column align-center trainingStatsElementContainer">
            <Text size="larger" weight="bold" content={customCompleted?.length} />
            <Divider color="black" important className="trainingStatsElementDivider" />
            <Text size="larger" weight="bold" content={customTrainings?.length} />
          </div>
          <Text align="center" size="small" content={t("Profile.LearningPath.customCompleted")} styles={{ width: "65%" }} />
        </div>
      ) : (
        <></>
      )}
      <div className="flex align-center justify-end trainingStatsElement">
        <div className="flex column align-center trainingStatsElementContainer">
          <Text size="larger" weight="bold" content={allCompletedTrainings} />
          <Divider color="black" important className="trainingStatsElementDivider" />
          <Text size="larger" weight="bold" content={allTrainings} />
        </div>
        <Text align="center" size="small" content={t("Profile.LearningPath.allCompleted")} styles={{ width: "65%" }} />
      </div>
    </div>
  );
}

export default TrainingsStats;
