import React, { useEffect, useState } from "react";
import { Dropdown } from "@fluentui/react-northstar";
import { CyberCoachGroup, CyberCoachGroups, DropdownValue, LearningPathPickerProps } from "../../utils/types/common";

export default function LearningPathPicker({
  handleLearningPathChange,
  handleLPArrayChange,
  selectedPath,
  cyberCoachGroups,
  manageMode,
  multiDropdown,
  inverted,
  fluid,
}: LearningPathPickerProps) {
  const [defaultValue, setDefaultValue] = useState<DropdownValue | DropdownValue[] | null | undefined>();
  const [dropDownValues, setDropDownValues] = useState<DropdownValue[]>();

  const isPathSelected = (path: string): boolean => {
    if (Array.isArray(selectedPath)) {
      return selectedPath.includes(path);
    }
    return selectedPath === path;
  };

  const handleDefaults = (dropDownItems: DropdownValue[]): void => {
    if (multiDropdown) {
      const selectedItems = dropDownItems?.filter((item) => item.selected) ?? [];
      setDefaultValue(selectedItems?.length > 0 ? selectedItems : null);
    } else {
      const selectedItem = dropDownItems?.find((item) => item.selected) ?? null;
      setDefaultValue(selectedItem);
    }
  };

  useEffect(() => {
    let dropDownOptions = (cyberCoachGroups as CyberCoachGroup[])?.map((group) => ({
      key: group?.groupId,
      header: group?.groupName,
      selected: isPathSelected(group?.groupId),
      active: isPathSelected(group?.groupId),
    }));
    if (manageMode) {
      dropDownOptions = (cyberCoachGroups as CyberCoachGroups[])?.map((group) => ({
        key: group?.groupId,
        header: group.groupTranslations[0].groupName,
        selected: isPathSelected(group?.groupId),
        active: isPathSelected(group?.groupId),
      }));
    }
    handleDefaults(dropDownOptions);
    setDropDownValues(dropDownOptions);
  }, [manageMode, selectedPath, multiDropdown, cyberCoachGroups]);

  function pickLearningPath(item: DropdownValue) {
    if (handleLearningPathChange && item) {
      handleLearningPathChange(item.key);
    }
    return "Learning path changed";
  }

  function addLearningPath(item: DropdownValue) {
    if (handleLPArrayChange) {
      const newLPArray = [...(selectedPath as string[]), item?.key];
      handleLPArrayChange(newLPArray);
    }
    return "Learning path added to list";
  }

  function removeLearningPath(item: DropdownValue) {
    if (Array.isArray(selectedPath) && handleLPArrayChange) {
      const newLPArray = selectedPath.filter((key: string) => key !== item?.key);
      handleLPArrayChange(newLPArray);
    }
    return "Learning path removed from list";
  }

  return (
    <>
      {multiDropdown ? (
        <Dropdown
          inverted={inverted}
          fluid={fluid}
          disabled={!dropDownValues}
          multiple
          align="bottom"
          checkable
          items={dropDownValues}
          value={defaultValue}
          defaultValue={defaultValue}
          placeholder={"Select learning paths" ?? ""}
          styles={{ minHeight: "32px" }}
          getA11ySelectionMessage={{
            onAdd: (item) => addLearningPath(item as DropdownValue),
            onRemove: (item) => removeLearningPath(item as DropdownValue),
          }}
        />
      ) : (
        <Dropdown
          disabled={!dropDownValues}
          inverted
          fluid
          styles={{ width: "100%" }}
          checkable
          items={dropDownValues}
          value={defaultValue}
          defaultValue={defaultValue}
          getA11ySelectionMessage={{
            onAdd: (item) => pickLearningPath(item as DropdownValue),
          }}
        />
      )}
    </>
  );
}
