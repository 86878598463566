import React, { useState, useRef } from "react";
import { Card, Header, Text, Checkbox, Loader } from "@fluentui/react-northstar";
import "../../Profile.css";
import { useTranslation } from "react-i18next";
import TrainingCatalogue from "./TrainingCatalogue";
import StartTrainingDialog from "../../../elements/StartTrainingDialog";
import useScreenSize from "../../../../utils/hooks/useScreenSize";
import useGetLearningPathNumbers from "../../../../utils/hooks/useGetLearningPathNumbers";
import { ProgressBar } from "./ProgressComponents";
import { ProgressProps, TrainingToLaunch } from "../../../../utils/types/profile";
import TrainingsStats from "./TrainingsStats";

export function Progress({ learningInfo, learningObjectives, handleModeChange }: ProgressProps) {
  const { t } = useTranslation();
  const { isMobile } = useScreenSize();

  const [mandatoryOnly, setMandatoryOnly] = useState<boolean>(false);
  const [trainingToLaunch, setTrainingToLaunch] = useState<TrainingToLaunch | undefined>();

  const progressBarRef = useRef<HTMLDivElement>(null);
  const learningPathInfo = useGetLearningPathNumbers(learningInfo, mandatoryOnly);
  const noMandatoryTrainings = learningPathInfo?.mandatoryTrainings === 0 && mandatoryOnly;
  // show progressbar and mandatory checkbox only if LP has CCtrainigns
  const onlyCustomTrainings = !learningInfo?.modules.length && learningInfo?.customContent.length;

  const handleMandatoryChecked = (value: boolean) => {
    setMandatoryOnly(!!value);

    if (progressBarRef.current) {
      progressBarRef.current.style.animation = "none";
      // Reflow to restart the animation
      void progressBarRef.current.offsetWidth; // Force reflow
      progressBarRef.current.style.animation = ""; // Restore animation
    }
  };

  return (
    <Card
      aria-roledescription="card avatar"
      elevated
      inverted
      className="profileCard"
      id="progress"
      styles={{ height: isMobile ? "fit-content" : "100%" }}
    >
      <Card.Header>
        <div className="flex gap-medium full-width" style={{ minHeight: "20%" }}>
          <div className="flex column full-width">
            <Header content={t("Profile.LearningPath.title")} className="cardHeadline" />
            {learningInfo?.chosenLearningPathName && <Text content={learningInfo?.chosenLearningPathName} />}
          </div>
        </div>
      </Card.Header>
      {learningInfo === null ? (
        <Loader />
      ) : (
        <div className="flex column gap-small full-width" style={{ maxHeight: "70%" }}>
          {!onlyCustomTrainings && (
            <ProgressBar
              completionRate={learningPathInfo?.completionRate ?? 0}
              progressBarRef={progressBarRef}
              noMandatoryTrainings={noMandatoryTrainings}
            />
          )}
          <div
            className={`flex column justify-start align-start full-width ${isMobile ? "gap-small" : "gap-smaller"}`}
            style={{ maxHeight: isMobile ? "100%" : "85%" }}
          >
            <div className="flex align-center gap-medium full-width">
              <Text align="start" content={t("Profile.LearningPath.nextTraining")} size="large" weight="bold" />
              {!onlyCustomTrainings && (
                <Checkbox
                  label={t("Profile.LearningPath.onlyMandatory")}
                  labelPosition="end"
                  onChange={(event) => handleMandatoryChecked(event.currentTarget.ariaChecked === "false")}
                />
              )}
            </div>
            <div className={`flex ${isMobile ? "column" : ""} full-width full-height ${isMobile ? "gap-large" : "gap-small"}`}>
              <TrainingCatalogue
                mandatoryOnly={mandatoryOnly}
                learningObjectives={learningObjectives}
                learningInfo={learningInfo}
                setTrainingToLaunch={setTrainingToLaunch}
                noMandatoryTrainings={noMandatoryTrainings}
              />
              {learningPathInfo && <TrainingsStats learningPathInfo={learningPathInfo} customTrainings={learningInfo?.customContent} />}
            </div>
          </div>
        </div>
      )}
      <StartTrainingDialog trainingToLaunch={trainingToLaunch} handleModeChange={handleModeChange} setTrainingToLaunch={setTrainingToLaunch} />
    </Card>
  );
}
