import React from "react";
import "./Badges.css";
import { useTranslation } from "react-i18next";
import { Text, Image, Tooltip, ExclamationTriangleIcon } from "@fluentui/react-northstar";
import { BadgeElementProps } from "../../../../utils/types/profile";
import getAllTrainingDetails from "../../../../utils/helpers/getAllTrainingDetails";
import { TrainingType } from "../../../../utils/types/common";
import { useCompanyData } from "../../../../utils/context/companyContext";
import { dateIsWithinNextMonth, isFutureDate } from "../../../../utils/helpers/checkDate";

function BadgeElement({ trainings, setTrainingToLaunch, lpExpiryDate }: BadgeElementProps) {
  const { t } = useTranslation();
  const { companyData } = useCompanyData();
  const isTrial = process.env.REACT_APP_ENV_TYPE === "trial";
  const longLearningPath = trainings && trainings?.length > 30;
  const gridLength = trainings && trainings?.length > 15 ? 4 : 3;

  return (
    <div
      className="grid grid-columns justify-center full-width full-height badgeElementGrid"
      style={{ "--columns": longLearningPath ? 5 : gridLength } as React.CSSProperties}
    >
      {trainings?.map((training) => {
        const { trainingName, trainingDialog, imageUrl, trainingType } = getAllTrainingDetails(training);
        const trainingToLaunchContent = {
          shortname: trainingDialog,
          name: trainingName,
          type: trainingType,
        };
        // training is really completed when completion is not expired
        const trainingCompleted = training?.completed && !training?.expired;
        // check if lpExpiryDate is one month in future
        const trainingExpiringSoon = trainingCompleted && lpExpiryDate && dateIsWithinNextMonth(lpExpiryDate);
        // check if lpExiryDate is in the future and show for completed trainings
        const trainingExpiring = trainingCompleted && lpExpiryDate && isFutureDate(lpExpiryDate);
        // check if training was expired in the past
        const trainingExpired = training?.completed && training?.expired && lpExpiryDate;
        // check if badge can be clicked
        const allowClickOnBadge = !isTrial && !training?.challengeExpired;

        //build the css classNames for the badge which depends on a set of variables
        //badgeBase is the normal badge image that is used for completed trainigns
        let badgeCssClassNames = "badgeBase";
        if (!trainingCompleted) {
          //styles for uncompleted trainings. Badge is greyed out. Mandatory trainings have an outline.
          badgeCssClassNames = badgeCssClassNames.concat(" ", training?.mandatory ? "unCompletedBadgeMandatory" : "unCompletedBadge");
        } else if (trainingExpiringSoon) {
          //styles for expiring trainings. Badge gets more opacity. Mandatory trainings have the outline
          badgeCssClassNames = badgeCssClassNames.concat(" ", training?.mandatory ? "expiringTrainingMandatory" : "expiringTraining");
        } else if (training?.mandatory) {
          //Completed mandatory training. Has an outline
          badgeCssClassNames = badgeCssClassNames.concat(" ", "completedBadgeMandatory");
        }

        if (!allowClickOnBadge) {
          //different cursor
          badgeCssClassNames = badgeCssClassNames.concat(" ", "badgeUnclickable");
        }

        return (
          <Tooltip
            key={trainingName}
            trigger={
              <div
                className={`badgeImageContent ${!trainingCompleted && "unCompletedTrainingContent"} ${
                  trainingExpiringSoon && "expiringTrainingContent"
                }`}
              >
                {/**"Click-on-badge" should not be available if scenario has expired or if we are in TRIAL */}
                <Image
                  src={imageUrl}
                  fluid
                  className={badgeCssClassNames}
                  onClick={() => allowClickOnBadge && setTrainingToLaunch(trainingToLaunchContent)}
                />
                {trainingExpiringSoon && <ExclamationTriangleIcon size="large" className="expiryIcon" />}
              </div>
            }
            content={
              <div className="flex column">
                <Text content={trainingName} />
                {/* show text when completed */}
                {training?.completedOn && (
                  <Text content={t("Profile.Badges.completedOn", { completionDate: new Date(training?.completedOn).toLocaleDateString() })} />
                )}
                {/* show when training is going to expire */}
                {trainingExpiring && (
                  <Text content={`${t("Profile.Trainings.completionExpiring")}: ${new Date(lpExpiryDate).toLocaleDateString()}`} />
                )}
                {/* show when training was expired */}
                {trainingExpired && <Text content={`${t("Profile.Trainings.completionExpired")}: ${new Date(lpExpiryDate).toLocaleDateString()}`} />}
                {/* show if this is a challenge and only available for a short time*/}
                {training?.challengeExpirydt && (
                  <Text
                    content={t("Profile.Trainings.challengeAvailableUntil", {
                      challengeExpiryDate: new Date(training?.challengeExpirydt).toLocaleDateString(),
                    })}
                  />
                )}
                {trainingType === TrainingType.CUSTOM && (
                  <Text content={t("Profile.CustomContent.subTitle", { companyName: companyData?.companyName })} />
                )}
              </div>
            }
          />
        );
      })}
    </div>
  );
}

export default BadgeElement;
