import React, { useState, useEffect } from "react";
import { Card, Header, Text, Loader, Alert } from "@fluentui/react-northstar";
import "../../Profile.css";
import { useTranslation } from "react-i18next";
import BadgeElement from "./BadgeElement";
import { useUserData } from "../../../../utils/context/userContext";
import { BadgesProps, ICombinedTraining, TrainingToLaunch } from "../../../../utils/types/profile";
import StartTrainingDialog from "../../../elements/StartTrainingDialog";
import getAllTrainings from "../../../../utils/helpers/getAllTrainings";

export function Badges({ learningInfo, handleModeChange }: BadgesProps) {
  const { t } = useTranslation();
  const { userData } = useUserData();
  const [badgeContent, setBadgeContent] = useState<ICombinedTraining[] | null | undefined>(null);
  const [completedTrainings, setCompletedTrainings] = useState<number | null>(null);
  const [trainingToLaunch, setTrainingToLaunch] = useState<TrainingToLaunch | undefined>();

  const hasTrainings = (learningInfo && learningInfo?.modules?.length > 0) || (learningInfo && learningInfo?.customContent?.length > 0);

  useEffect(() => {
    const { ccTrainings, customTrainings } = getAllTrainings(learningInfo);

    // add training completion date to badge
    const trainingBadgesArray = ccTrainings?.map((training) => {
      const completedTraining = userData?.badgeData && userData.badgeData.find((badge) => badge.training === training.dialog && badge.completedOn);
      return {
        ...training,
        completedOn: completedTraining?.completedOn || null,
      };
    });

    const badgeArray = customTrainings.length ? [...trainingBadgesArray, ...customTrainings] : trainingBadgesArray;
    const completedTrainings = badgeArray?.filter((t) => t.completed && !t.expired).length;

    setBadgeContent(badgeArray);
    setCompletedTrainings(completedTrainings);
  }, [learningInfo, userData?.badgeData]);

  return (
    <Card aria-roledescription="card avatar" elevated inverted styles={{ minWidth: "100%" }} id="badges">
      <Card.Header styles={{ width: "100%", height: "10%" }}>
        <div className="flex gap-medium full-width">
          <div className="flex column full-width">
            <Header content={t("Profile.Badges.title")} className="cardHeadline" />
            <div className="flex justify-space-between full-width">
              <div className="flex column gap-small">
                {learningInfo && (
                  <div className="flex gap-smaller">
                    <Text content={t("Profile.Badges.subTitle") + ":"} weight="bold" />
                    {learningInfo?.chosenLearningPathName && <Text content={learningInfo?.chosenLearningPathName} />}
                  </div>
                )}
              </div>
              {badgeContent && <Text align="center" content={`${completedTrainings}/${badgeContent?.length}`} weight="bold" size="large" />}
            </div>
          </div>
        </div>
      </Card.Header>
      {learningInfo === null ? (
        <Loader />
      ) : (
        <div className="flex full-width" style={{ height: "90%" }}>
          <div className="flex column justify-space-between gap-small full-height">
            {!hasTrainings && <Alert content={t("Profile.LearningPath.emptyTrainingRow")} />}
            <BadgeElement
              trainings={badgeContent}
              setTrainingToLaunch={setTrainingToLaunch}
              lpExpiryDate={learningInfo?.chosenLearningPathTrainingExpiryDate}
            />
            <div className="flex justify-center align-center gap-smaller">
              <div style={{ width: "1.2em", height: "1.2em", backgroundColor: "#cc33ff" }}></div>
              <Text content={t("Profile.Badges.mandatoryTraining")} />
            </div>
          </div>
        </div>
      )}
      <StartTrainingDialog trainingToLaunch={trainingToLaunch} handleModeChange={handleModeChange} setTrainingToLaunch={setTrainingToLaunch} />
    </Card>
  );
}
