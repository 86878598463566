import { ApplicationInsights, ICustomProperties } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";

const reactPlugin = new ReactPlugin();
const browserHistory = createBrowserHistory();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_INSIGHTS_INSTRUMENTATION_KEY ?? "InstrumentationKey=N/A",
    extensions: [reactPlugin],
    autoTrackPageVisitTime: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});
appInsights.loadAppInsights();

/**
 * Tracks an error occured.
 *
 * @param {Error} error
 * @param {(ICustomProperties | undefined)} customProperties - Optional object with additional data
 */
function trackAppInsightException(error: Error, customProperties: ICustomProperties | undefined) {
  appInsights.trackException({ exception: error }, customProperties);
}

/**
 * Implicitly tracks a certain event.
 *
 * @param {string} eventName
 * @param {(ICustomProperties? | undefined)} customProperties - Optional object with additional data
 */
function trackAppInsightsEvent(eventName: string, customProperties?: ICustomProperties | undefined) {
  appInsights.trackEvent({ name: eventName }, customProperties);
}

export { reactPlugin, appInsights, trackAppInsightException, trackAppInsightsEvent };
