import React from "react";
import { Card, Header, Text, Avatar, CardHeader, CardBody, QuestionCircleIcon, Tooltip } from "@fluentui/react-northstar";
import "../Profile.css";
import { fetchFromBackend } from "../../lib/makeBackendCall";
import { LanguagePicker } from "../../elements/LanguagePicker";
import { useTranslation } from "react-i18next";
import TonePicker from "../../elements/TonePicker";
import LearningPathPicker from "../../elements/LearningPathPicker";
import getGroupInfo from "../../../utils/helpers/getGroupInfo";
import { useUserData } from "../../../utils/context/userContext";
import { useCompanyData } from "../../../utils/context/companyContext";
import { UserCardProps } from "../../../utils/types/userCard";
import useScreenSize from "../../../utils/hooks/useScreenSize";

function UserCard({ cyberCoachGroups, userName, userPicture }: UserCardProps) {
  const { t, i18n } = useTranslation();
  const { companyData } = useCompanyData();
  const { userData, setUserData } = useUserData();
  const { isMobile } = useScreenSize();

  const currentGroup = cyberCoachGroups?.find((group) => group.groupId === userData?.cyberCoachGroup?.groupId);

  async function pickGroup(groupId: string) {
    const groupInfo = getGroupInfo(groupId, cyberCoachGroups);
    await fetchFromBackend("cybercoachGroup/" + groupId, "PUT");
    setUserData({ ...userData, cyberCoachGroup: groupInfo });
  }

  async function pickLanguage(languageCode: string) {
    i18n.changeLanguage(languageCode);
    await fetchFromBackend("user/userLanguage/" + languageCode, "PUT");
    setUserData({ ...userData, userLanguage: languageCode });
  }

  async function pickTone(tone: string) {
    await fetchFromBackend("user/tonePreference/" + tone, "PUT");
    setUserData({ ...userData, tonePreference: tone });
  }

  return (
    <Card aria-roledescription="card avatar" elevated inverted className="profileCard" id="userCard">
      <Card.Header>
        <div className="flex gap-medium">
          <Avatar image={userPicture} name={userName} size="larger" />
          <div className="flex column">
            <Header content={t("Profile.UserCard.title")} className="cardHeadline" />
            <Text content={userName} size="small" />
          </div>
        </div>
      </Card.Header>
      <div className="flex column gap-medium justify-space-between">
        <div className="flex align-center justify-center gap-medium">
          <div className="flex column full-width gap-medium">
            <Card elevated inverted styles={{ width: "100%" }}>
              <CardHeader>
                <Text content={t("Profile.UserCard.settings")} weight="bold" size="large" />
              </CardHeader>
              <CardBody>
                <div className="flex column gap-small" style={{ width: isMobile ? "100%" : "90%" }}>
                  <div className="flex column gap-smaller">
                    <Text weight="bold" content={t("Profile.UserCard.language")} />
                    <div className="flex justify-space-between align-center gap-small">
                      <LanguagePicker
                        companyData={companyData}
                        defaultSelected={userData?.userLanguage}
                        handleLanguageChange={pickLanguage}
                        inverted
                        fluid
                      />
                      <div style={{ width: "2rem" }} />
                    </div>
                  </div>
                  <div className="flex column gap-smaller">
                    <Text weight="bold" content={t("Profile.UserCard.tonePreference")} />
                    <div className="flex justify-space-between align-center gap-small">
                      <TonePicker defaultSelected={userData?.tonePreference} handleToneChange={pickTone} inverted fluid />
                      <div style={{ width: "2rem" }}>
                        <Tooltip
                          trigger={<QuestionCircleIcon outline />}
                          content={<p dangerouslySetInnerHTML={{ __html: t("Profile.UserCard.tonePreferenceTooltip") ?? "" }} />}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex column gap-smaller">
                    <Text content={t("Profile.UserCard.learningPath")} weight="bold" />
                    <div className="flex justify-space-between align-center gap-small">
                      <LearningPathPicker
                        cyberCoachGroups={cyberCoachGroups}
                        selectedPath={currentGroup?.groupId}
                        handleLearningPathChange={pickGroup}
                      />
                      <div style={{ width: "2rem" }}>
                        {currentGroup?.groupDesc && <Tooltip trigger={<QuestionCircleIcon outline />} content={currentGroup.groupDesc} />}
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </Card>
  );
}

export default UserCard;
