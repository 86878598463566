import React from "react";
import { Loader, Header, Text } from "@fluentui/react-northstar";

export function LoaderHelper({ header, text, showLoader }: { header: string; text: string; showLoader?: boolean }) {
  return (
    <div className="flex column justify-center align-center" style={{ height: "90%" }}>
      {showLoader && (
        <pre className="fixed">
          <Loader size="largest" />
        </pre>
      )}
      <Header content={header} align="center" />
      <Text content={text} />
    </div>
  );
}
