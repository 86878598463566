import { Providers } from "@microsoft/mgt-react";
import { CLIENT_TEAMS, CLIENT_MICROSOFT_WEB, CLIENT_SLACK } from "./authHelpers";
import { fetchFromBackend } from "./makeBackendCall";
import { IClientInfo } from "../../utils/context/clientInfoContext";

export async function getUserGroups(clientInfo: IClientInfo | null | undefined) {
  const client = clientInfo?.client;

  if (client) {
    //we have the microsoft provider. Get from graph.
    if ((client === CLIENT_MICROSOFT_WEB || client === CLIENT_TEAMS) && Providers.globalProvider) {
      const userGroups = await Providers.globalProvider.graph.client.api("me/memberOf").select("id").get();
      return userGroups.value;
    }
    //this is Slack. Get from backend that gets data from slack API
    else if (client === CLIENT_SLACK) {
      const [data] = await fetchFromBackend("slack/userGroups");
      return data.userGroups;
    }
    //no such concept as usergroups from provider
    else {
      return [];
    }
  } else {
    return [];
  }
}

export async function getUserGroupNames(clientInfo: IClientInfo) {
  const client = clientInfo.client;

  //we have the microsoft provider. Get from graph.
  if ((client === CLIENT_MICROSOFT_WEB || client === CLIENT_TEAMS) && Providers.globalProvider) {
    const userGroupNames = await Providers.globalProvider.graph.client.api("groups").select("id,displayName").get();
    return userGroupNames.value;
  }
  //this is Slack. Get from backend that gets data from slack API
  else if (client === CLIENT_SLACK) {
    const [data] = await fetchFromBackend("slack/userGroups/names");
    return data.userGroups;
  }
  //no such concept as usergroups from provider
  else {
    return [];
  }
}
