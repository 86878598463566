import { fetchFromBackend } from "./makeBackendCall";

/**
 * If temptoken IS present it tries to exchange a temporary token which is the result of the "Login with Slack process".
 * Slack gives us a temptoken which the backend can use to exchange for a real one while validating the authentication.
 *
 * If temptoken is NOT present it means the page has been loaded. It is possible that user is already authenticated and has just pressed reload on the browser.
 * In this case we do not have a temptoken but we anyway send a request to the token endpoint to figure out if user is authenticated.
 *
 * If authenticated returns some info about the slack organization.
 * If not authenticated throws an error sending user back to login page prompting a new login
 */

export async function verifySlackUserAccess(temptoken: any) {
  //replace state (hide temptoken and make URL look neat)
  window.history.replaceState(null, "CyberCoach Dashboard", window.location.origin);
  const response = await fetch("/token", {
    headers: {
      "Content-Type": "application/json",
      temptoken: temptoken,
    },
    method: "POST",
  });
  if (!response.ok) {
    throw new Error("Slack user not authenticated");
  }
  const responseJson = await response.json();
  if (responseJson.error) {
    throw new Error(responseJson.error);
  }
  /**
   * In order to facilitate the signingprocess so that users with many slack orgs won't get confused the slackhome passes the teamId of the org. This information we need as response
   * {
   *  slackTeamId : string
   * }
   * */
  return responseJson;
}

//get user information from slack
export async function getSlackuserInfo() {
  const [data] = await fetchFromBackend("slack/userInfo");
  const userJson = {
    displayName: data.name,
    userPicture: data.picture,
    tid: data["https://slack.com/team_id"],
    id: data.sub,
    teamName: data["https://slack.com/team_name"],
  };
  return userJson;
}
