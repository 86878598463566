import React from "react";
import "./Feedback.css";
import { Form, Text, FormCheckbox, FormTextArea, FormSlider, Label, FormButton } from "@fluentui/react-northstar";
import { useTranslation } from "react-i18next";

function FeedbackContent({
  submitted,
  submitting,
  submitError,
  feedbackContent,
  setFeedbackContent,
  submitFeedback,
}: {
  submitted: boolean;
  submitting: boolean;
  submitError: boolean;
  feedbackContent: any;
  setFeedbackContent: any;
  submitFeedback: any;
}) {
  const { t } = useTranslation();
  return (
    <>
      {submitted && (
        <div className="flex column gap-medium align-start feedbackModalElement">
          <Text content={t("Feedback.thankYou2")} />
          <Text content={t("Feedback.thankYou3")} />
        </div>
      )}
      {submitError && (
        <div className="flex column gap-medium align-start feedbackModalElement">
          {/* TODO: add translations */}
          <Text weight="bold" content={"Something went wrong :("} />
          <Text content={"Not being able to give feedback is indeed very bad."} />
          <Text content={"We are monitoring the service constantly and this error will for sure catch our attention."} />
          <Text content={"You can also reach out to the CyberCoach team using some other challenge while we are fixing this. Apologies."} />
        </div>
      )}
      {!submitting && !submitted && (
        <Form
          className="feedbackForm"
          onSubmit={() => {
            submitFeedback(feedbackContent);
          }}
        >
          <div className="flex column gap-smaller">
            <Text weight="bold" content={t("Feedback.increaseUnderstandingQuestion")} />
            <div className="flex">
              <FormCheckbox
                labelPosition="start"
                label={t("General.Yes")}
                id="IncreaseUnderstandingYes"
                checked={feedbackContent.increaseUnderstanding.answer === "Yes"}
                onClick={() =>
                  setFeedbackContent({
                    ...feedbackContent,
                    increaseUnderstanding: { ...feedbackContent.increaseUnderstanding, answer: "Yes" },
                  })
                }
              />
              <FormCheckbox
                labelPosition="start"
                label={t("General.No")}
                id="IncreaseUnderstandingNo"
                checked={feedbackContent.increaseUnderstanding.answer === "No"}
                onClick={() =>
                  setFeedbackContent({
                    ...feedbackContent,
                    increaseUnderstanding: { ...feedbackContent.increaseUnderstanding, answer: "No" },
                  })
                }
              />
            </div>
            {feedbackContent.increaseUnderstanding.answer !== "N/A" && (
              <FormTextArea
                onInput={(e: React.FormEvent<HTMLTextAreaElement>) =>
                  setFeedbackContent({
                    ...feedbackContent,
                    increaseUnderstanding: { ...feedbackContent.increaseUnderstanding, reason: (e.target as HTMLTextAreaElement).value },
                  })
                }
                fluid
                label={t("Feedback.whyQuestion")}
                id="whyIncrease"
                rows={3}
              />
            )}
          </div>
          <div className="flex column gap-smaller">
            <Text weight="bold" content={t("Feedback.enjoyableQuestion")} />
            <div className="flex">
              <FormCheckbox
                labelPosition="start"
                label={t("General.Yes")}
                id="EnjoyableYes"
                checked={feedbackContent.enjoyable.answer === "Yes"}
                onClick={() => setFeedbackContent({ ...feedbackContent, enjoyable: { ...feedbackContent.enjoyable, answer: "Yes" } })}
              />
              <FormCheckbox
                labelPosition="start"
                label={t("General.No")}
                id="EnjoyableeNo"
                checked={feedbackContent.enjoyable.answer === "No"}
                onClick={() => setFeedbackContent({ ...feedbackContent, enjoyable: { ...feedbackContent.enjoyable, answer: "No" } })}
              />
            </div>
            {feedbackContent.enjoyable.answer !== "N/A" && (
              <FormTextArea
                onInput={(e: React.FormEvent<HTMLTextAreaElement>) =>
                  setFeedbackContent({
                    ...feedbackContent,
                    enjoyable: { ...feedbackContent.enjoyable, reason: (e.target as HTMLTextAreaElement).value },
                  })
                }
                fluid
                label={t("Feedback.whyQuestion")}
                id="whyIncrease"
                rows={3}
              />
            )}
          </div>
          <div className="flex column">
            <Text weight="bold" content={t("Feedback.likelyRecommendQuestion")} />
            <Text weight="light" size="small" content={t("Feedback.recommendNo")} />
            <Text weight="light" size="small" content={t("Feedback.recommendVery")} />
            <div className="flex gap-small feedbackSlider">
              <div className="flex column align-center">
                <FormSlider
                  value={feedbackContent.likelyToRecommend}
                  onChange={(e, data) => setFeedbackContent({ ...feedbackContent, likelyToRecommend: data?.value ?? "" })}
                  min={0}
                  max={10}
                  step={1}
                  required
                />
                <Label content={feedbackContent.likelyToRecommend} />
              </div>
            </div>
          </div>
          <div className="flex column gap-smaller">
            <Text weight="bold" content={t("Feedback.topicsFeaturesQuestion")} />
            <FormTextArea
              value={feedbackContent.newFeaturesTopics}
              onInput={(e: React.FormEvent<HTMLTextAreaElement>) =>
                setFeedbackContent({ ...feedbackContent, newFeaturesTopics: (e.target as HTMLTextAreaElement).value })
              }
              fluid
              id="whatFeaturesTopics"
              rows={5}
            />
          </div>
          <FormButton content={t("Feedback.submitButton")} primary />
        </Form>
      )}
    </>
  );
}

export default FeedbackContent;
