import React, { useState } from "react";
import "./Feedback.css";
import { Button, Dialog, CloseIcon, Loader, NotepadPersonIcon, RobotIcon, Menu, tabListBehavior } from "@fluentui/react-northstar";
import { fetchFromBackend } from "../lib/makeBackendCall";
import { useTranslation } from "react-i18next";
import { BugContent, FeedbackContentType, FeedbackProps } from "../../utils/types/feedback";
import BugReportContent from "./BugReportContent";
import FeedbackContent from "./FeedbackContent";

const initialFeedbackContent = {
  increaseUnderstanding: {
    answer: "N/A",
    reason: "",
  },
  enjoyable: {
    answer: "N/A",
    reason: "",
  },
  likelyToRecommend: "5",
  newFeaturesTopics: "",
};
const initialBugContent = { bugLocation: "", errorDesc: "", bugDate: undefined, bugTime: "" };

export function Feedback({ width }: FeedbackProps) {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const [feedbackDialog, setFeedbackDialog] = useState<boolean>(false);

  const [feedbackContent, setFeedbackContent] = useState<FeedbackContentType>(initialFeedbackContent);
  const [bugContent, setBugContent] = useState<BugContent>(initialBugContent);

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<boolean>(false);

  const thankyouMessage = activeIndex === 0 ? t("Feedback.thankYou1") : t("BugReport.thankYou1");

  async function submitFeedback(content: any) {
    setSubmitting(true);
    const feedbackItem = {
      conversationId: "N/A",
      scenarioName: "",
      scenarioVersion: "",
      scenarioRating: "",
      feedbackCategory: activeIndex === 1 ? "BugReport " : "General",
      feedback: content,
    };
    try {
      await fetchFromBackend("feedback", "POST", feedbackItem);
      setSubmitted(true);
    } catch (err) {
      console.error("Failed to give feedback or report a bug");
      setSubmitError(true);
    }
    setFeedbackContent(initialFeedbackContent);
    setBugContent(initialBugContent);
    setSubmitting(false);
  }

  const menuItems = [
    {
      key: "feedback",
      icon: <NotepadPersonIcon />,
      content: t("Feedback.header"),
    },
    {
      key: "bugReport",
      icon: <RobotIcon />,
      content: t("BugReport.header"),
    },
  ];
  const renderTabContent = () => {
    switch (activeIndex) {
      case 0:
        return (
          <FeedbackContent
            submitted={submitted}
            submitting={submitting}
            submitError={submitError}
            feedbackContent={feedbackContent}
            setFeedbackContent={setFeedbackContent}
            submitFeedback={submitFeedback}
          />
        );
      case 1:
        return (
          <BugReportContent
            submitted={submitted}
            submitting={submitting}
            submitError={submitError}
            bugContent={bugContent}
            setBugContent={setBugContent}
            submitFeedback={submitFeedback}
          />
        );
      default:
        return null;
    }
  };
  return (
    <>
      <Button styles={{ width: width }} onClick={() => setFeedbackDialog(true)} content={t("Feedback.header")} tinted />
      <Dialog
        className="feedbackDialog"
        open={feedbackDialog}
        onCancel={() => setFeedbackDialog(false)}
        content={
          <div className="flex column justify-center align-center gap-medium feedbackContainer">
            {submitting ? (
              <Loader label={t("Feedback.submitting")} />
            ) : (
              <>
                {!submitted && (
                  <Menu
                    activeIndex={activeIndex}
                    onActiveIndexChange={(e, data) => setActiveIndex(data?.activeIndex as number)}
                    items={menuItems}
                    underlined
                    primary
                    accessibility={tabListBehavior}
                    aria-label="Feedback"
                    className="feedbackMenu"
                  />
                )}
                <div className="flex feedbackContentContainer">{renderTabContent()}</div>
              </>
            )}
          </div>
        }
        header={submitted ? thankyouMessage : t("Feedback.header")}
        headerAction={{
          icon: <CloseIcon />,
          title: t("General.Close"),
          onClick: () => {
            setFeedbackDialog(false);
            setSubmitted(false);
            setSubmitError(false);
          },
        }}
      />
    </>
  );
}
